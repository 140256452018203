$border-grey: #ccc;
$border-dark-grey: #a9a9a9;

h2 {
  font-weight: bold;
  margin: 15px 0;
}

h3 {
  font-weight: bold;
  margin: 15px 0;
}

.checkout.viewcart {
  padding: 1em;
  margin-top: 10px;
}

section.panel {
  margin: 30px 11px 0;
  border-bottom: 1px solid #cdcbc9;
  padding-bottom: 30px;
}

.messages {
  background: transparent;
  border: none;
  margin: 10px 0;
  padding: 0;
}

.checkout {
  .messages {
    color: $red;
  }
}

#promo-panel {
  margin: 0;
}

#mobile-breadcrumb {
  display: none;
}

#viewcart-panel {
  margin: 0;
  border: none;
  .viewcart-header {
    border-bottom: 1px solid #dbdbdb;
    padding: 0.4em 1em;
    float: left;
    width: 100%;
    font-weight: bold;
    h2 {
      float: left;
    }
    .item-count {
      float: right;
      margin: 20px 0;
    }
  }
  .single-message {
    color: red;
  }
  .viewcart-panel__content {
    .cart-header {
      display: none;
    }
    .signin-to-see-cart a {
      text-decoration: underline;
    }
    .checkout-table {
      .thumb {
        width: 22%;
        float: left;
        margin-bottom: 1em;
      }
      .desc {
        width: 66%;
        margin: 20px 15px;
        float: left;
        padding-right: 0;
        padding-left: 10px;
        .product_name {
          display: none;
        }
      }
      .price {
        width: 40%;
        padding-left: 15px;
        clear: both;
        float: left;
        display: block;
      }
      .qty {
        width: 25%;
        float: left;
        &.cart-item {
          &__qty {
            &__engraving-max {
              width: 25%;
              select {
                border: 1px solid $light-red;
                min-width: 42px;
              }
            }
          }
        }
      }
      .total {
        width: 30%;
        float: left;
      }
      .replenishment.cart-item__replenishment {
        clear: both;
        margin-left: 40%;
      }
    }
  }
}

body#viewcart {
  #recommended-products-panel {
    .recommended-product-items {
      .recommended-item {
        width: 100%;
        float: none;
        margin: 0 auto;
        text-align: center;
        a {
          float: none;
          img {
            width: 40%;
          }
        }
        .description {
          float: none;
          margin: 0 auto;
          width: auto;
        }
      }
    }
  }
  .engraving-wrapper.mobile {
    width: 100%;
    .engraving-view-control {
      text-align: center;
      font-weight: bold;
      width: 100%;
      margin-top: 13px;
      float: left;
    }
    .engraving-view-title {
      margin: 10px;
    }
    .engraving-toggle-edit {
      margin-left: 15px;
      display: inline-block;
    }
  }
}

#delivery-options-panel {
  header {
    margin: 0.5em 0;
    font-weight: bold;
    h2 {
      margin: 0 0 0.5em 0;
    }
  }
  .delivery-date-wrapper {
    display: block;
    margin-bottom: 20px;
  }
}

.checkout__sidebar {
  margin: 0;
  border-top: none;
  #need-help-panel {
    display: none;
  }
  #delivery-options-panel {
    border: none;
  }
  .tab-switcher {
    display: none;
  }
  #offer-code-panel {
    border: none;
    header {
      margin: 0.5em 0;
    }
    input {
      width: 54%;
      margin-bottom: 30px;
      &.form-submit {
        width: 28%;
      }
    }
  }
  #links-panel {
    border-top: 1px solid #cdcbc9;
    border-bottom: none;
    margin: 0;
    padding: 30px 11px;
    header {
      margin: 0.5em 0;
    }
    .content {
      .links_list {
        li {
          margin-bottom: 5px;
          a {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

section.viewcart-buttons-panel {
  border: none;
  margin: 30px 0;
  .go-shopping {
    display: none;
    &:hover {
      background: $color-off-black;
      color: $white;
      text-decoration: none;
    }
  }
  .continue-shopping {
    display: block;
    float: left;
    width: 30%;
  }
  .continue-checkout-btns {
    float: right;
    width: 70%;
    .continue-checkout {
      float: right;
      padding: 9px 19px 8px 19px;
      min-width: 116px;
      max-width: 100%;
      background: $color-off-black;
      color: $white;
    }
  }
}

#add-samples-message {
  display: none;
}

#order-summary-panel {
  header {
    margin: 0.5em 0;
    font-weight: bold;
    h2 {
      margin: 0 0 0.5em 0;
    }
  }
  .order-summary__content {
    .label {
      float: left;
      margin-bottom: 1.5em;
      width: 75%;
    }
    .value {
      float: right;
      width: 25%;
    }
    .total {
      border-top: 1px solid #cdcbc9;
      font-weight: bold;
      margin-bottom: 0;
      padding: 1em 0;
    }
  }
}

#samples {
  #samples-panel {
    margin-top: 124px;
    .samples-buttons.top {
      display: none;
      .return-link {
        float: left;
        margin-top: 10px;
      }
      .add-button {
        float: right;
      }
    }
    .product-img {
      width: 50%;
      float: left;
    }
    .product-name {
      display: none;
    }
    .product-subhead {
      width: 50%;
      float: right;
      margin-top: 20px;
    }
    .product-size {
      width: 50%;
      float: right;
      margin-top: 10px;
    }
    .sample-select-button {
      clear: both;
      width: 100%;
      padding: 9px 19px 8px 19px;
      background: $color-off-black;
      color: $white;
      display: block;
      text-align: center;
      margin: 1em 0;
    }
    .view-larger {
      display: none;
    }
    .samples-panel__heading {
      font-size: 18px;
      text-transform: none;
    }
    .samples-panel__promo-text {
      display: inline-block;
      &.large {
        display: none;
      }
    }
  }
  .viewcart-buttons-panel {
    float: left;
    width: 100%;
    border-top: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
    margin: 30px 0;
    padding: 10px;
    .edit-cart {
      float: left;
      margin-top: 10px;
    }
    .continue-buttons {
      float: right;
      .continue-checkout {
        padding: 9px 19px 8px 19px;
        background: $color-off-black;
        color: $white;
        text-align: center;
        margin-right: 10px;
        display: block;
      }
    }
  }
}

#index {
  #sign-in-panel {
    #new-or-returning-radios {
      .new-customer {
        margin-bottom: 5px;
      }
      .returning-customer {
        margin-bottom: 5px;
      }
    }
    input {
      width: 100%;
    }
    input[type='radio'] {
      width: auto;
    }
  }
  #shipping-panel {
    .checkout__panel-title {
      display: inline-block;
    }
    .edit {
      float: right;
      margin-top: 20px;
      text-decoration: underline;
    }
    #checkout_shipping_panel {
      input {
        width: 100%;
      }
      select {
        width: 100%;
        margin-bottom: 20px;
      }
      input[type='radio'] {
        width: auto;
        & ~ label {
          width: 90%;
          clear: none;
          display: inline-block;
          margin-left: 10px;
        }
      }
      input[type='checkbox'] {
        width: auto;
        & ~ label {
          width: 90%;
          clear: none;
          display: inline-block;
          margin-left: 10px;
        }
      }
      .checkout-esalon {
        line-height: 1.5;
        .esalon__salon-finder-link {
          text-decoration: underline;
        }
      }
    }
  }
  #viewcart-panel {
    display: block !important;
    visibility: visible;
  }
  #order-summary-panel {
    display: block;
  }
  #offer-code-panel {
    border-bottom: 1px solid #dbdbdb;
  }
  #payment-panel {
    .payment-container {
      padding: 10px 0;
      select {
        width: 100%;
        margin-bottom: 10px;
      }
      input[type='radio'] {
        float: left;
        margin-right: 6px;
      }
      label {
        float: left;
        width: 43%;
      }
      &__option--rakuten {
        margin-top: 10px;
        & ~ label {
          width: 93%;
          margin-top: 10px;
        }
      }
    }
    .submit {
      margin-bottom: 10px;
    }
    .payment-edit-content {
      .expires-month-wrapper label {
        width: 100%;
        display: block;
      }
    }
    .cvv {
      margin-top: 20px;
    }
    .continue-button-wrapper {
      &.rakuten {
        input {
          width: 300px;
          background: no-repeat center/100% url('/media/export/cms/checkout/rpay_btn.png');
          color: transparent;
        }
      }
    }
  }
  .engraving_option {
    display: none;
  }
  .engraving-wrapper {
    width: 100%;
    padding: 0;
    .engraving-create {
      min-height: 130px;
      border-bottom: 2px solid $border-dark-grey;
      border-top: 2px solid $border-dark-grey;
    }
    .engraving-view-control,
    .engraving-view-footer {
      display: none;
    }
    .engraving-view-title-top {
      margin-left: 12px;
    }
    .engraving-view-message-content {
      .monogram_custom_text {
        padding: 0 10%;
      }
    }
    .engraving-view-message {
      width: 95%;
      margin: 0 10px 15px;
    }
  }
}

.active-panel-registration {
  #offer-code-panel,
  #links-panel {
    display: block;
  }
}

#confirmation-page {
  #confirmation-panel {
    margin: 0;
    header {
      padding: 25px 0;
      width: 100%;
      text-align: center;
    }
    .content {
      border-top: 1px solid #cdcbc9;
      margin: 10px;
    }
    .confirmation-panel__actions {
      margin: 10px;
      .print-buttons {
        .btn {
          background-color: $color-off-black;
          color: $white;
          width: auto;
          padding: 9px 19px 8px 19px;
        }
      }
      .return-link-container {
        .button {
          background-color: $color-off-black !important;
          border: none;
          border-radius: initial;
          padding: 9px 19px 8px 19px !important;
          line-height: 19px !important;
          height: auto;
        }
      }
    }
  }
}

.engraving_option {
  clear: both;
  display: inline-block;
  width: 100%;
  margin-top: 10px;
  background-color: $color-light-linen;
  .engraving-cart-control {
    margin: 10px;
  }
  .engraving-show-toggle,
  .engraving-toggle-cancel {
    padding: 0 6px;
    font-size: 11px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-weight: bold;
    border: solid 2px $color-off-black;
    letter-spacing: 0;
    width: 100%;
    margin-top: 20px;
  }
  .engraving-toggle-cancel {
    background-color: $color-light-linen;
    color: $color-off-black;
  }
  .engraving-show-toggle {
    background-color: $color-off-black;
    color: $color-light-linen;
  }
}

.engraving-wrapper {
  background-color: $color-light-linen;
  padding: 1em;
  overflow: hidden;
  .engraving-form-element-content,
  .engraving-service-text {
    margin: 20px 10px 10px;
  }
  .monogram-error {
    margin-bottom: 10px;
    color: $red;
  }
  .monogram_custom_text {
    input {
      &.engraving-message {
        width: 100%;
      }
    }
    label {
      margin-bottom: 5px;
    }
  }
  select {
    margin: 10px auto;
  }
  .engraving-label {
    &.engraving-selected {
      display: block;
      text-align: center;
    }
  }
  .engraving-message-count {
    display: block;
    clear: both;
    margin-bottom: 10px;
  }
  select {
    &.monogram_custom_image_right {
      width: 100%;
    }
  }
  .background-color img {
    width: 100%;
    padding: 5% 0;
    vertical-align: bottom;
  }
  .action-buttons-div {
    width: 100%;
    margin: 10px 0 20px 0;
    clear: both;
    .action-anchor,
    .engraving-cancel:first-child {
      display: block;
      color: $color-off-black;
      border: 2px solid $color-off-black;
      float: none;
      font-weight: bold;
      padding: 12px;
      margin: 10px auto;
      text-decoration: none;
      text-align: center;
      width: 50%;
    }
  }
  div.engraving-view {
    padding-top: 1em;
  }
  .engraving-view-title {
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0;
  }
  .engraving-view-message {
    text-align: center;
    cursor: pointer;
    font-size: 26px;
    height: 65px;
    background: $white;
    clear: both;
    width: 100%;
    float: left;
    .engraving-view-message-content {
      overflow: auto;
      overflow-y: hidden;
      border: 2px solid $border-grey;
      div {
        white-space: nowrap;
        word-wrap: normal;
        font-size: 18px;
        line-height: 65px;
        vertical-align: middle;
        img {
          height: auto;
          width: 45px;
          vertical-align: middle;
        }
        .monogram_custom_text {
          font-size: 20px;
          padding: 0 15% 0 0;
          display: inline-block;
          white-space: pre;
        }
      }
    }
  }
  .background-color {
    .monogram_custom_text {
      margin-top: 20px;
    }
  }
  .engraving-toggle-edit {
    display: block;
    margin-top: 10px;
  }
  .engraving-view-footer {
    float: left;
    margin: 3% 0 3% 5%;
  }
}
.birth-month-container {
  .birthday-panel {
    &__title {
      font-size: 15px;
      margin-bottom: 20px;
    }
  }
  .select-birth-month {
    height: 3em;
    margin: 15px 0;
    width: 98%;
  }
  .checkout-birthday {
    &__descriptive-text {
      padding-top: 10px;
    }
  }
  input[type='submit'] {
    &.btn {
      margin-bottom: 20px;
    }
  }
}
.offer-code {
  &__byline {
    &.game1,
    &.game2,
    &.game3,
    &.game4 {
      display: none;
    }
  }
}

.engraving_motif {
  img {
    width: 50px;
    height: auto;
  }
}
