.site-content {
  .offer-block-formatter {
    .site-email-signup__fields {
      .site-email-signup__field {
        display: block;
      }
    }
    .site-email-signup__terms-conditions__more {
      margin-bottom: 0;
    }
  }
}

.site-email-signup__success {
  &.welcome-15 {
    &.success {
      padding: 2.5em 0.5em 0.5em 0.8em;
      .site-email-signup__success-header {
        width: auto;
        font-size: 38px;
      }
      .site-email-signup__success-offer {
        width: auto;
        font-size: 24px;
      }
      .site-email-signup__success-reminder {
        width: auto;
      }
    }
  }
  .welcome-15 & {
    padding: 2em 0 0 1.5em;
    .site-email-signup__success-offer {
      font-size: 20px;
      padding-bottom: 14px;
    }
    .site-email-signup__success-terms {
      width: 245px;
    }
  }
}

.store-locator__results-container {
  .store-locator__tooltip-product-types {
    text-transform: uppercase;
    font-size: 12px;
    .pc {
      padding: 2px 2px 0px 2px;
      &.pc {
        &--fs {
          background-color: #d39939;
        }
        &--ms {
          background-color: #8a3180;
        }
        &--am {
          background-color: #ddc63b;
        }
        &--hs {
          background-color: #2d8e58;
        }
        &--cs {
          background-color: #c93742;
        }
        &--lss {
          background-color: #3173a5;
        }
        &--sp {
          background-color: #389dcc;
        }
      }
    }
  }
}

.getthefacts {
  &__store_meta_info {
    text-align: left;
  }
}

.esearch-nav {
  &__popular {
    text-align: left;
  }
  &__popular-list {
    font-size: 1em;
    display: block;
    padding: 0.25em;
    a {
      font-size: 1em;
      display: block;
      padding: 0.25em;
    }
  }
  .search-form {
    input[type='text'] {
      &.search-form__field {
        font-size: 1em;
      }
    }
  }
}

.spp__container {
  .spp_customer_reviews {
    #pr-reviewdisplay {
      .p-w-r {
        .pr-review-snapshot-snippets {
          .pr-snippet-read-and-write {
            margin-top: 25px;
          }
        }
      }
    }
  }
}

.spp-content {
  .product-full {
    .selectBox {
      background-color: $white;
    }
  }
}

.tout {
  &__text {
    &--center-center {
      position: absolute;
    }
  }
}

.site {
  &-header {
    &__nav {
      display: flex;
      flex-direction: column;
    }
    &__content-block-sitewide-banner {
      order: 3;
    }
  }
}
