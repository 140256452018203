#password_request,
#password_sent,
#password_reset {
  #customer-service {
    display: none;
  }
}

.address-book-page__overlay {
  .address-overlay {
    .address-form {
      .form-item {
        input.btn {
          background: $color-off-black;
          margin-bottom: 1.5em;
        }
        &.phone_option {
          float: left;
          margin-bottom: 0.5em;
          .radio.field {
            float: left;
            margin-right: 1.5em;
          }
        }
        &.phone_option_number {
          float: left;
        }
      }
    }
  }
  #cboxClose {
    right: 10px;
  }
}

#profile_preferences {
  #newsletter-info {
    #pc-promotions {
      .label {
        width: 90%;
      }
    }
    #mobile-promotions {
      .label {
        width: 90%;
      }
    }
  }
  .reg-youraddress {
    margin-top: 1.5em;
    border-top: 1px dashed #ccc;
    #demographic-questions {
      fieldset {
        margin: 15px 0;
        span.label-content {
          font-weight: bold;
        }
        .your-address-input {
          margin: 5px 0;
          input {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.favorites-page__wrapper {
  .js-add-to-cart {
    min-width: 70px;
    width: 70%;
    padding: 5px;
    font-size: 1em;
  }
}

.cart-item {
  &__monogram {
    .cart-item {
      &__monogram-text {
        img {
          width: 30px;
        }
      }
    }
  }
}

.getthefacts {
  &__salon_header {
    h2 {
      font-weight: 400;
    }
  }
}
