$ab-small-down: $medium-down;

.appt-book {
  .location-select-container {
    #location-select {
      font-size: 12px;
    }
  }
  .appt-book-section {
    .appt-book-section-content {
      .collapsible-block__title {
        display: flex;
        justify-content: space-between;
        .collapsible-block__icon {
          display: flex;
          justify-content: center;
          flex-direction: column;
          float: right;
          position: initial;
        }
      }
      .service-content__select-section {
        width: 95% !important;
        margin-top: 20px;
      }
      .selection-selector,
      .selection-data {
        width: 150px !important;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
      .service-content__time-section {
        text-align: center;
        margin: 0 auto;
        float: none !important;
      }
      .booking-container {
        .appt-book-book-submit {
          @include breakpoint($ab-small-down) {
            float: none;
          }
        }
      }
    }
  }
  .my-appointments {
    .section-header {
      &:after {
        top: 2.75em;
      }
    }
  }
  .confirm-container {
    .error-messages {
      .error-message {
        a {
          color: $color-error;
        }
      }
    }
    .registration__privacy-list {
      margin-top: 30px !important;
    }
  }
}

#appt-book-my-appointments-overlay {
  .overlay-content-container {
    width: 90% !important;
    max-width: 500px;
    margin-left: 0 !important;
    transform: translate(-50%, -50%) !important;
  }
}

.appointment-cancellation {
  .appt-book-link {
    display: inline-block;
    float: none;
    margin-bottom: 0px;
  }
}

.appt-book-overlay {
  .overlay-content-container {
    .overlay-close {
      cursor: pointer;
      text-align: right;
      display: block;
    }
  }
}
