$english-font: $primary-font;

html,
body {
  font-family: $jp-primary-font;
}

.original-font {
  font-family: $english-font;
}

.site-header__menu {
  .site-header__menu-list--desktop {
    .menu__subsection {
      .menu--lvl-1 {
        .menu__item--lvl-1 {
          @media only screen and (min-width: 768px) and (orientation: portrait) {
            margin-#{$rdirection}: 10px;
          }
          &:nth-child(2),
          &:nth-child(4) {
            a {
              font-family: $english-font;
            }
          }
          a {
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0.05em;
          }
        }
      }
      .menu--lvl-2 {
        .menu__item--lvl-2 {
          width: 21%;
          a {
            font-size: 14px;
            font-weight: 700;
            line-height: 1.5;
          }
        }
      }
      .menu--lvl-3 {
        .menu__item--lvl-3 {
          a {
            font-size: 13px;
            font-weight: normal;
            line-height: 1.5;
            padding: 0.4em 1.5em 0.4em 0;
            white-space: nowrap;
            &:hover {
              font-weight: bold;
            }
          }
        }
      }
    }
    .menu--lvl-1 {
      .menu__item--lvl-1 {
        @include breakpoint($medium-up) {
          margin-#{$rdirection}: 10px;
        }
        @include breakpoint($large-up) {
          margin-#{$rdirection}: 25px;
        }
      }
      .menu__item--lvl-1 {
        &:nth-child(2),
        &:nth-child(4) {
          a {
            font-family: $english-font;
          }
        }
        &:nth-child(3) {
          .menu--lvl-2 {
            .menu__item--lvl-2 {
              width: 26%;
            }
          }
        }
      }
    }
    .menu--lvl-3 {
      .menu__item--lvl-3 {
        a {
          font-size: 13px;
          line-height: 1.5;
          padding: 0.4em 1.5em 0.4em 0;
          white-space: nowrap;
          &:hover {
            font-weight: bold;
          }
        }
      }
    }
    .menu__item--tout {
      width: 37%;
    }
  }
}

.content-block-large__text--3 > p {
  width: 100%;
  margin: 0 auto;
  letter-spacing: 0.15em;
  font-size: 0.8em;
  line-height: 1.5em;
  margin-top: 0.9em;
}

.tabbed-block__text {
  font-size: 0.8em;
  letter-spacing: 0.15em;
  font-weight: 700;
}

.tabbed-block__tabs a {
  min-width: 90px;
  width: auto;
  padding: 10px 0;
}

body.page-products {
  .content-block-large__text--1 {
    p {
      font-size: 0.6em;
    }
  }
}

.mpp-container {
  .product-quickshop-wrapper {
    .product__button--add-to-bag {
      padding: 9px 14px 8px 14px;
    }
  }
}

.spp__container {
  .product-full__breadcrumb {
    font-size: 12px;
    letter-spacing: 0.1em;
  }
  .product-full__quote {
    font-size: 16px;
    letter-spacing: 0.1em;
  }
  .product-full {
    .product-sku-price {
      text-align: $ldirection;
    }
  }
  .olapic-wall-widget {
    .olapic-loadmore {
      font-family: $english-font;
    }
  }
  .product__button--add-to-bag {
    min-width: 20em;
  }
}

.spp-cross-sells__products {
  .product__button--add-to-bag {
    min-width: 100%;
  }
}

.homepage-wrapper {
  .content-block-large__cta-wrap {
    .content-block-large__cta {
      a {
        font-size: 12px;
        letter-spacing: 0.05em;
      }
    }
  }
  .button--clear-dark {
    letter-spacing: 0.05em;
  }
  .pramasana-text-row-1 {
    strong {
      letter-spacing: 0.15em;
      font-weight: 700;
      font-size: 1em;
    }
  }
  .feel-the-care-row-1 {
    font-size: 0.75em;
    letter-spacing: 0.1em;
    font-weight: 700;
    line-height: 1.25;
    margin-bottom: 0.5em;
  }
}

.device-pc {
  @media (min-width: 1024px) {
    .footer {
      .site-footer {
        &__sticky {
          padding-bottom: 12px;
          &--offer {
            #{$rdirection}: auto;
          }
        }
      }
    }
  }
}

.device-mobile {
  .welcome-15 {
    .site-email-signup__success {
      padding: 2em 1.5em;
      .site-email-signup__success-offer {
        font-size: 16px;
      }
    }
  }
  .mpp-container {
    .product-quickshop {
      .product-quickshop__actions {
        margin: 20px 0;
      }
    }
  }
  .site-footer {
    .site-footer__professional {
      width: 80%;
      .menu__link--lvl-1 {
        font-weight: 700;
      }
      .menu__link--lvl-2 {
        line-height: 1.45;
        margin-bottom: 0.5em;
        letter-spacing: 0.1em;
      }
    }
    .site-footer__connect {
      .menu--lvl-3 {
        display: inline-block;
        margin-#{$ldirection}: 0.5em;
      }
    }
    .site-footer__sticky--offer {
      #{$ldirection}: auto;
      #{$rdirection}: 0;
    }
    .sticky-livechat__headline,
    .sticky-offer__headline {
      margin: 2px 0;
    }
  }
}
/* mobile */
.site-header__nav {
  .site-header__menu {
    .site-header__menu-list--mobile {
      .menu--lvl-1 {
        .menu__item--lvl-1 {
          a {
            font-size: 16px;
          }
        }
        .menu--lvl-2 {
          .menu__item--lvl-2.active {
            a:hover {
              font-weight: 700;
              line-height: 2.5;
              letter-spacing: 0.05em;
            }
          }
        }
      }
    }
  }
}

.field-mobile-collection-slides {
  .header--medium {
    font-size: 18px;
    line-height: 1.25;
  }
}

.section-customer-service {
  section.cs-page__content {
    h1 {
      font-size: 1.5em;
      font-weight: 700;
    }
  }
  .cs-section__col {
    h3 {
      font-size: 1em;
      font-weight: 700;
    }
  }
}

.section-customer-service-contact-us-page {
  .content-header {
    .support {
      li {
        margin-#{$ldirection}: 0;
      }
    }
  }
}

#node-8512 {
  .content-block-large__text--1 {
    p {
      font-size: 0.6em;
      font-weight: 700;
    }
  }
}

#node-8516 {
  .basic-tout__text-copy.header--medium {
    p {
      font-size: 0.65em;
      letter-spacing: 0.05em;
      font-weight: 700;
      line-height: 1.2;
      margin-bottom: 0.5em;
    }
  }
}

.basic-grid__item-inner.basic-grid__item-inner--32 {
  .basic-tout__text-copy.header--small {
    p {
      font-size: 0.8em;
    }
  }
}

.store-locator {
  .basic-tout__cta {
    .button {
      padding: 9px 15px 8px 15px;
    }
  }
  &.store-locator--results {
    .store-locator {
      &__product_filters-item {
        &:nth-child(1) {
          .store-locator {
            &__product_filters-item-image {
              background-image: url('/media/images/locator/icon_sp.png') !important;
            }
          }
        }
      }
    }
  }
}

#index {
  #shipping-panel {
    .js-sagawa-express-info {
      text-decoration: underline;
    }
    .checkout-panel-click_and_collect {
      padding: 0;
      .pickpoint-details {
        margin-bottom: 20px;
      }
      a.choose-location {
        margin-top: 10px;
      }
    }
    .click-and-collect-method-select {
      label {
        display: inline-block;
        margin-#{$ldirection}: 1em;
      }
      input[name='select-click-and-collect'] {
        vertical-align: top;
      }
    }
  }
  .edit-address {
    div.click_and_collect-standard-shipping {
      label {
        display: inline-block;
        margin-#{$ldirection}: 1em;
      }
    }
  }
}

#delivery-info-shipping-note,
.pickpoint-payment-note {
  font-size: 0.8em;
  margin-top: 10px;
}

#delivery-info {
  .select-box--disabled {
    .select-box__label {
      border: 1px solid $color-gray;
      color: $color-gray;
    }
  }
}

.offer-banner {
  .content-block-split__text h2 {
    font-size: 22px;
    @include breakpoint($medium-up) {
      font-size: 32px;
    }
    span {
      font-size: 14px;
      @include breakpoint($medium-up) {
        font-size: 18px;
      }
    }
  }
}

#colorbox.sagawa-express-overlay {
  p {
    font-size: 14px;
    margin-bottom: 25px;
  }
  h2 {
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 5px;
  }
}

.has-sale {
  .product-sku-price__value {
    color: $color-off-black;
  }
}

.optanon-show-settings-popup-wrapper {
  .optanon-show-settings-button {
    &.optanon-toggle-display {
      .optanon-show-settings-middle {
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
        .optanon-show-settings {
          font-family: $jp-primary-font;
        }
      }
    }
  }
}

button {
  &#ot-sdk-btn {
    &.ot-sdk-show-settings {
      color: $color-off-black !important;
      text-decoration: none !important;
      &:hover {
        text-decoration: underline !important;
        color: $gray !important;
      }
    }
  }
}

#index,
#confirm {
  .checkout__content {
    .messages {
      .single-message {
        .engraving-error {
          display: none;
        }
      }
    }
  }
}
.viewcart-buttons-panel {
  .content {
    .continue-buttons {
      .continue-checkout-btns {
        .disable-checkout {
          pointer-events: none;
        }
      }
    }
  }
}

.quiz-page {
  .site-header__menu {
    @include breakpoint($medium-up) {
      height: auto;
    }
  }
  .split-width-box__content label {
    @include breakpoint($medium-up) {
      font-size: 20px;
    }
  }
}

.salon_page {
  .getthefacts-container {
    .tabbed-block__tabs a {
      padding: 9px 19px 8px;
    }
    .getthefacts__store {
      &_bookheader {
        top: 169px;
      }
      &_meta_info {
        text-align: center;
        &--store_phone {
          .phone {
            display: none;
          }
        }
        &--store_hours {
          table {
            width: 60%;
          }
          td {
            text-align: center;
          }
        }
      }
    }
    .getthefacts__services {
      @include breakpoint($medium-up) {
        width: 100%;
      }
      &_column {
        table {
          width: 100%;
        }
        td {
          padding-bottom: 14px;
        }
        .salon-services {
          width: 59%;
        }
      }
    }
    .content-block-large__text--3 > p {
      margin: 0 auto;
    }
  }
  .checkout-esalon {
    .esalon__form-item--checkbox {
      margin-top: 20px;
      .esalon__checkbox {
        margin-top: 6px !important;
      }
    }
    .esalon__stylist_name {
      display: block;
      margin-bottom: 12px;
    }
  }
}

.getthefacts {
  &-checkout {
    .header-primary-menu {
      display: none !important;
    }
  }
  &__salon-artists__header {
    font-size: 25px;
    @include breakpoint($medium-up) {
      font-size: 38px;
    }
  }
  &__salon-social__header {
    text-transform: none;
  }
  &__salon {
    &-locations {
      &__header {
        text-transform: none;
      }
    }
  }
}

.salon-takeover {
  .header-primary-menu {
    display: block !important;
  }
  .artist_desc {
    font-size: 12px;
    @include breakpoint($medium-up) {
      font-size: 14px;
    }
  }
  .getthefacts {
    &__store_facts {
      font-size: 25px;
      @include breakpoint($medium-up) {
        font-size: 30px;
      }
    }
  }
  .basic-grid {
    .arttitle,
    .artist_desc {
      @include breakpoint($medium-up) {
        width: 100%;
        padding-top: 10px;
      }
    }
    &__item {
      width: 95%;
      margin-#{$ldirection}: 7px;
      @include breakpoint($medium-up) {
        width: 50%;
        margin-#{$ldirection}: 0;
      }
    }
  }
}

.tout__header--medium {
  font-size: 24px;
  @include breakpoint($medium-up) {
    font-size: 28px;
  }
}

.video-landing-tile__title {
  font-size: 14px;
  @include breakpoint($medium-up) {
    font-size: 20px;
  }
}
#order_detail {
  .order-products {
    .cart-item {
      .cart-item__products {
        .cart-item__monogram {
          &-text {
            white-space: pre;
          }
        }
      }
    }
  }
}
