#confirm {
  .change-password {
    .book-appt-fieldset {
      .signin-block__field-wrapper {
        &.password-field {
          height: auto;
        }
      }
    }
  }
}

.appt-book-section {
  .error-messages {
    color: $color-red;
  }
}

.change-password {
  .single_messages {
    color: $color-red;
  }
  .password-field {
    &__info {
      &-signin {
        @include breakpoint($large-up) {
          #{$ldirection}: 97%;
        }
      }
      &-reset {
        display: flex;
      }
      &-checkout {
        display: flex;
        z-index: 99;
        @include breakpoint($large-up) {
          #{$ldirection}: 97%;
        }
      }
      &-my_appoint {
        @include breakpoint($large-up) {
          #{$ldirection}: 65%;
          width: 200px;
        }
      }
    }
  }
}
