// Tazugane Info StdN W05 Thin
@font-face {
  font-family: 'Tazugane';
  src: url('#{$netstorage-fonts-path}cjk/Tazugane_JP/9a164f5a-9cd6-4b06-b621-d512280565b6.woff2')
    format('woff2');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Tazugane';
  src: url('#{$netstorage-fonts-path}cjk/Tazugane_JP/9a164f5a-9cd6-4b06-b621-d512280565b6.woff2')
    format('woff2');
  font-style: normal;
  font-weight: 400;
}

// Tazugane Info StdN W05 Medium
@font-face {
  font-family: 'Tazugane';
  src: url('#{$netstorage-fonts-path}cjk/Tazugane_JP/6b6ffb4b-5d14-4931-9b34-46f1dde6711d.woff2')
    format('woff2');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Tazugane';
  src: url('#{$netstorage-fonts-path}cjk/Tazugane_JP/6b6ffb4b-5d14-4931-9b34-46f1dde6711d.woff2')
    format('woff2');
  font-style: normal;
  font-weight: 700;
}
