@import 'overrides/overrides';
@import '../../aveda_base/scss/aveda-base-mobile';
@import '../../aveda_base/template_api/editorial/flex_grid_formatter_v1/src/flex_grid_formatter_v1';
@import '../../aveda_base/template_api/editorial/tabbed_block_v1/src/tabbed_block_v1';
@import 'jp_fonts';
@import 'all';

@import 'sections/_gwp_mobile';

// SMACSS/sections/account
@import 'sections/mobile/account/account';

// SMACSS/sections/checkout
@import 'sections/mobile/checkout/checkout';

// Appointment Book Styles
@import 'sections/mobile/_appt_booking_mobile';

// One Trust Cookie Styles.
@import '../../aveda_base/scss/sections/one_trust_cookie/cookie_settings_ot';

// Password tooltip for password strengthening.
@import 'sections/pc/account/account-reset-password-strength';

// Online Appointment Booking Styles
@import 'sections/appointment_booking.scss';

.site {
  &-header {
    &__nav {
      display: flex;
      flex-direction: column;
    }
    &__content-block-sitewide-banner {
      order: 3;
    }
  }
}
